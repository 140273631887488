import React from "react";
import PropTypes from "prop-types";

function ZeusSaas({ variant }) {
  const zeus2Path = "https://www.washingtonpost.com/zeus/main.js";
  const zeus3Path = "https://wapo-neo.zeustechnology.com/main.js";

  // For the 90/10 split for this test core team is using the "segmentation cloudlet"
  // which results in these base16 variant ids
  const isVariantB = variant === "bd6f491cfaef7f1f";
  const zeusPath = isVariantB ? zeus3Path : zeus2Path;

  return <script src={zeusPath} async />;
}
ZeusSaas.propTypes = {
  variant: PropTypes.string
};
export default ZeusSaas;

export function loadZeusSass(variant) {
  const zeus2Path = "https://www.washingtonpost.com/zeus/main.js";
  const zeus3Path = "https://wapo-neo.zeustechnology.com/main.js";

  // For the 90/10 split for this test core team is using the "segmentation cloudlet"
  // which results in these base16 variant ids
  const isVariantB = variant === "bd6f491cfaef7f1f";
  const zeusPath = isVariantB ? zeus3Path : zeus2Path;

  return [
    <script src={zeusPath} async key="zeus-sass" data-testid="zeus-sass" />
  ];
}
